<template>
  <div class="Lanchpad" :class="{lan_En : lan != 'zh'}">
    <div class="page_cont1">
      <div class="title animation_hide" v-animate="{delay: 0, class:'amplify'}">
        <p>{{ $t('lanchpad.cont1.title1') }}</p>
        <p>{{ $t('lanchpad.cont1.desc') }}</p>
      </div>
      <div class="text delay300 animation_hide" v-animate="{delay: 300, class:'amplify'}">{{ $t('lanchpad.cont1.title2') }}</div>
      <div class="btnList delay400 animation_hide" v-animate="{delay: 400, class:'amplify'}">
        <div class="btn-shadow">
          <div class="btn" @click="linkHref1('/node')">{{ $t('lanchpad.cont1.btn5') }}</div> 
          <div class="shadow"></div>
        </div>
        <div class="btn-shadow">
          <div class="btn" @click="linkHref1('/software')">{{ $t('lanchpad.cont1.btn6') }}</div> 
          <div class="shadow"></div>
        </div>
        <div class="btn-shadow">
          <div class="btn btn1" @click="linkHref('https://www.deeplink.cloud/public-sale/')">{{ $t('lanchpad.cont1.btn4') }}</div> 
        </div>
      </div>
      <div class="carousel">
        <div class="carousel-inner" ref="carouselInner">
          <div class="carousel-item" v-for="(image, index) in displayedImages" :key="index">
            <img :src="image" loading="lazy" alt="Image" />
          </div>
        </div>
      </div>
    </div>
    <coverage></coverage>
    <div class="page page_cont2">
      <div class="videoPaly">
        <video ref="videoRef" src="https://www.deeplink.cloud/vedio.mp4"
          loop="loop" 
          muted="muted" 
          controls="controls"
          controlsList="noplaybackrate, nopip">
        </video>
      </div>
    </div>
    <div class="page page_cont3">
      <div class="page3_bg">
        <div class="page3_bg1"></div>
        <div class="page3_bg2"></div>
      </div>
      <div class="page3_cont animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
        <div class="video1">
          <video src="../assets/lanch_video1.mp4"
            loop="loop" 
            muted="muted" 
            autoplay>
          </video>
        </div>
        <div class="text">
          <p class="distance">Distance</p>
          <p class="num"><span>&lt;&nbsp;&nbsp;</span> 50km <span>&nbsp;&nbsp;&gt;</span></p>
        </div>
        <div class="video2">
          <video src="../assets/lanch_video2.mp4"
            loop="loop" 
            muted="muted" 
            autoplay>
          </video>
        </div>
      </div>
    </div>
    <div class="page page_cont4 animation_hide " v-animate="{delay: 0, class:'fadeInRight'}">
      <div class="page4_cont">
        <div class="cont_num">1ms</div>
        <div class="cont_text">{{ $t('lanchpad.cont3.text1') }}</div>
      </div>
      <div class="page4_cont">
        <div class="cont_num">8K</div>
        <div class="cont_text">{{ $t('lanchpad.cont3.text2') }}</div>
      </div>
      <div class="page4_cont">
        <div class="cont_num">244Hz</div>
        <div class="cont_text">{{ $t('lanchpad.cont3.text3') }}</div>
      </div>
      <div class="page4_cont">
        <div class="cont_num">24H</div>
        <div class="cont_text">{{ $t('lanchpad.cont3.text4') }}</div>
      </div>
    </div>
    <div class="page page_cont5">
      <div class="page5_bg"></div>
      <div class="page5_cont">
        <div class="page5_text">
          <div class="text1 animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
            <p>{{ $t('lanchpad.cont3.desc1') }}</p>
            <img class="arrow" loading="lazy" src="../assets/image_img/page5_arrow.png" alt="arrow">
          </div>
          <div class="text2 animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">{{ $t('lanchpad.cont3.desc2') }}</div>
        </div>
        <div class="page5_img animation_hide" v-animate="{delay: 0, class:'amplify'}"><img src="../assets/image_img/page5_img.png"  loading="lazy" alt="image"></div>
      </div>
    </div>
    <div class="page page_cont6">
      <div class="page6_bg"></div>
      <div class="page6_cont">
        <div class="page6_title animation_hide" v-animate="{delay: 0, class:'amplify'}">
          <p><span>{{ $t('lanchpad.cont16.title1_1') }}</span></p>
          <p>{{ $t('lanchpad.cont16.title1_2') }}</p>
        </div>
        <div class="page6_text animation_hide" v-animate="{delay: 0, class:'amplify'}">{{ $t('lanchpad.cont16.title2') }}</div>
        <div class="page6_shows animation_hide" v-animate="{delay: 0, class:'fadeInUp'}">
          <div class="show_cont">
            <div class="title">{{ $t('lanchpad.cont16.text1') }}</div>
            <div class="product">
              <div class="img"><img  loading="lazy" src="../assets/image_img/logo.png"/></div> 
              <div class="link"><p>deeplink.cloud</p> <a href="https://www.deeplink.cloud/" target="_blank"><img  loading="lazy" src="../assets/image_img/page6_link.png"/></a></div>
            </div>
          </div>
          <div class="show_cont mt_80">
            <div class="title">{{ $t('lanchpad.cont16.text2') }}</div>
            <div class="product">
              <div class="img"><img  loading="lazy" src="../assets/image_img/banner7.png"/></div>
              <div class="link"><p>hycons.cloud</p> <a href="https://www.hycons.cloud/" target="_blank"><img  loading="lazy" src="../assets/image_img/page6_link.png"/></a></div>
              <div class="img"><img  loading="lazy" src="../assets/image_img/logo.png"/></div> 
              <div class="link"><p>deeplink.cloud</p> <a href="https://www.deeplink.cloud/" target="_blank"><img  loading="lazy" src="../assets/image_img/page6_link.png"/></a></div>
            </div>
          </div>
          <div class="show_cont mt_80">
            <div class="title">{{ $t('lanchpad.cont16.text3') }}</div>
            <div class="product">
              <div class="img"><img  loading="lazy" src="../assets/image_img/page6_img2.png"/></div>
              <div class="img"><img  loading="lazy" src="../assets/image_img/page6_img1.png"/></div>
              <div class="img"><img  loading="lazy" src="../assets/image_img/page6_img3.png"/></div>
              <div class="img"><img  loading="lazy" src="../assets/image_img/banner7.png"/></div>
            </div>
          </div>
          <div class="show_cont">
            <div class="title">{{ $t('lanchpad.cont16.text4') }}</div>
            <div class="product">
              <div class="span">{{ $t('lanchpad.cont16.text5') }}</div>
              <div class="span">{{ $t('lanchpad.cont16.text6') }}</div> 
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page page_cont7 animation_hide" v-animate="{delay: 0, class:'amplify'}">
      <div class="page7_cont">
        <div class="page7_title">
          <p><span>{{ $t('lanchpad.cont4.title1_1') }}</span></p>
          <p>{{ $t('lanchpad.cont4.title1_2') }}</p>
        </div>
        <div class="page7_text">{{ $t('lanchpad.cont4.title2') }}</div>
        <div class="page7_tool delay300 animation_hide" v-animate="{delay: 300, class:'fadeInDown'}">{{ $t('lanchpad.cont4.desc1') }}</div>
        <div class="page7_desc">
          <div class="desc_block delay300 animation_hide" v-animate="{delay: 300, class:'fadeInLeft'}">{{ $t('lanchpad.cont4.desc2') }}</div>
          <div class="desc_img"><img  loading="lazy" src="../assets/image_img/page7_img1.png" alt="image"></div>
          <div class="desc_block delay300 animation_hide" v-animate="{delay: 300, class:'fadeInRight'}">{{ $t('lanchpad.cont4.desc3') }}</div>
        </div>
        <div class="page7_num delay300 animation_hide" v-animate="{delay: 300, class:'fadeInUp'}">
          <div class="num_block mt_50">
            <div class="text1">{{ $t('lanchpad.cont4.text1_1') }}</div>
            <div class="text2">{{ $t('lanchpad.cont4.text1_2') }}</div>
          </div>
          <div class="num_block">
            <div class="text1">{{ $t('lanchpad.cont4.text2_1') }}</div>
            <div class="text2">{{ $t('lanchpad.cont4.text2_2') }}</div>
          </div>
          <div class="num_block">
            <div class="text1">{{ $t('lanchpad.cont4.text3_1') }}</div>
            <div class="text2">{{ $t('lanchpad.cont4.text3_2') }}</div>
          </div>
          <div class="num_block mt_50 ">
            <div class="text1">{{ $t('lanchpad.cont4.text4_1') }}</div>
            <div class="text2">{{ $t('lanchpad.cont4.text4_2') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="page page_cont8 animation_hide" v-animate="{delay: 300, class:'fadeInUp'}">
      <div class="page8_title">{{ $t('lanchpad.cont8_new1.title') }}</div>
      <div class="page8_btns">
        <a class="page8_btn" href="https://www.youtube.com/watch?v=lO7XVxveGR8" target="_blank" >
          <img  loading="lazy" class="icon" src="../assets/image_img/page8_icon.png" alt="image">
          <p>Lazy Assassin</p>
        </a>
        <a class="page8_btn" href="https://www.youtube.com/watch?v=6slRE67heX8" target="_blank" >
          <img  loading="lazy" class="icon" src="../assets/image_img/page8_icon.png" alt="image">
          <p>Awesome Genome</p>
        </a>
        <a class="page8_btn" href="https://www.youtube.com/watch?v=G9MvdCAsngY" target="_blank" >
          <img  loading="lazy" class="icon" src="../assets/image_img/page8_icon.png" alt="image">
          <p>ThanhTrung Gaming</p>
        </a>
        <a class="page8_btn" href="https://www.youtube.com/watch?v=dl0C4uAFz0o" target="_blank" >
          <img  loading="lazy" class="icon" src="../assets/image_img/page8_icon.png" alt="image">
          <p>Gaming Generation</p>
        </a>
      </div>
    </div>
    <div class="page page_cont9 animation_hide" v-animate="{delay: 0, class:'amplify'}">
      <div class="page9_title">{{ $t('lanchpad.cont17.title1') }}</div>
      <div class="page9_pros">
        <div class="pro_block">
          <div class="text1">{{ $t('lanchpad.cont17.text1') }}</div>
          <div class="text2">{{ $t('lanchpad.cont17.desc1') }}</div>
        </div>
        <div class="pro_block">
          <div class="text1">{{ $t('lanchpad.cont17.text2') }}</div>
          <div class="text2">{{ $t('lanchpad.cont17.desc2') }}</div>
        </div>
      </div>
    </div>
    <div class="page page_cont10 animation_hide" v-animate="{delay: 0, class:'amplify'}">
      <div class="page10_bg"></div>
      <div class="page10_title">{{ $t('lanchpad.cont5.title1') }}</div>
      <div class="page10_cont">
        <div class="cont_block left">
          <div class="title">{{ $t('lanchpad.cont5.title2') }}</div>
          <div class="text">{{ $t('lanchpad.cont5.text2') }}</div>
          <img  loading="lazy" class="bg_img" src="../assets/image_img/page10_img3.png" alt="image">
        </div>
        <div class="cont_center">
          <div class="center_top">
            <div class="center_block">{{ $t('lanchpad.cont5.desc1') }}</div>
            <div class="center_block left">
              <p>{{ $t('lanchpad.cont5.desc2_1') }}</p>
              <p>{{ $t('lanchpad.cont5.desc2_2') }}</p>
            </div>
          </div>
          <div class="center_bottom">{{ $t('lanchpad.cont5.desc3') }} <br/>{{ $t('lanchpad.cont5.desc3_2') }}</div>
        </div>
        <div class="cont_block right">
          <div class="title">{{ $t('lanchpad.cont5.title3') }}</div>
          <div class="text">{{ $t('lanchpad.cont5.text3') }}</div>
          <img  loading="lazy" class="bg_img" src="../assets/image_img/page10_img2.png" alt="image">
        </div>
      </div>
    </div>
    <div class="page page_cont11">
      <div class="page11_title animation_hide" v-animate="{delay: 0, class:'fadeInDown'}">
        <p>{{ $t('lanchpad.cont6.title2') }}</p>
        <p>{{ $t('lanchpad.cont6.title1') }}</p>
      </div>
      <div class="page11_cont">
        <div class="cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont6.text1') }}</p>
            <img  loading="lazy" class="icon" src="../assets/image_img/page11_icon2.png" alt="image">
          </div>
          <p class="left_text">{{ $t('lanchpad.cont6.desc1') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon animation_hide" v-animate="{delay: 0, class:'fadeInDown'}" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class="cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="right_top">
            <img  loading="lazy" class="icon" src="../assets/image_img/page11_icon2.png" alt="image">
            <p class="text">{{ $t('lanchpad.cont6.text2') }}</p>
          </div>
          <p class="right_text">{{ $t('lanchpad.cont6.desc2') }}</p>
        </div>
      </div>
      <div class="page11_cont">
        <div class="cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont6.text3') }}</p>
            <img  loading="lazy" class="icon" src="../assets/image_img/page11_icon3.png" alt="image">
          </div>
          <p class="left_text">{{ $t('lanchpad.cont6.desc3') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon animation_hide" v-animate="{delay: 0, class:'fadeInDown'}" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class="cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="right_top">
            <img  loading="lazy" class="icon" src="../assets/image_img/page11_icon4.png" alt="image">
            <p class="text">{{ $t('lanchpad.cont6.text4') }}</p>
          </div>
          <p class="right_text">{{ $t('lanchpad.cont6.desc4') }}</p>
        </div>
      </div>
      <div class="page11_cont">
        <div class="cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont6.text6') }}</p>
            <img  loading="lazy" class="icon" src="../assets/image_img/page11_icon3.png" alt="image">
          </div>
          <p class="left_text">{{ $t('lanchpad.cont6.desc5') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon animation_hide" v-animate="{delay: 0, class:'fadeInDown'}" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class="cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="right_top">
            <img  loading="lazy" class="icon" src="../assets/image_img/page11_icon4.png" alt="image">
            <p class="text">{{ $t('lanchpad.cont6.text5') }}</p>
          </div>
          <p class="right_text">{{ $t('lanchpad.cont6.desc6') }}</p>
        </div>
      </div>
    </div>
    <div class="page page_cont12">
      <div class="page12_title animation_hide" v-animate="{delay: 0, class:'amplify'}">{{ $t('lanchpad.cont7.title1') }}</div>
      <div class="page12_text animation_hide" v-animate="{delay: 0, class:'amplify'}">{{ $t('lanchpad.cont7.desc1') }}</div>
      <div class="page12_cont animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
        <div class="cont_img1">
          <img  loading="lazy" class="icon" src="../assets/image_img/page12_img1bg.png" alt="image">
        </div>
        <div class="cont_block1">
          <div class="title">{{ $t('lanchpad.cont7.title2') }}</div>
            <p class="text">{{ $t('lanchpad.cont7.text1') }}</p>
            <p class="text">{{ $t('lanchpad.cont7.text2') }}</p>
            <p class="text">{{ $t('lanchpad.cont7.text3') }}</p>
        </div>
      </div>
      <div class="page12_cont animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
        <div class="cont_block2">
          <div class="title">{{ $t('lanchpad.cont7.title3') }}</div>
            <p class="text">{{ $t('lanchpad.cont7.text4') }}</p>
            <p class="text">{{ $t('lanchpad.cont7.text5') }}</p>
            <p class="text">{{ $t('lanchpad.cont7.text6') }}</p>
        </div>
        <div class="cont_img2"></div>
      </div>
    </div>
    <div class="page page_cont13">
      <div class="page13_title">{{ $t('lanchpad.cont8.title1') }}</div>
      <div class="page13_cont animation_hide" v-animate="{delay: 0, class:'fadeInUp'}">
        <div class="cont_left">
          <div class="block1 block text1">{{ $t('lanchpad.cont8_new.text7') }}</div>
          <div class="block2 block text2">{{ $t('lanchpad.cont8.text1') }}</div>
          <div class="block3 block text2">{{ $t('lanchpad.cont8.text2') }}</div>
          <div class="block4 block">
            <div class="text1">{{ $t('lanchpad.cont8.text16') }}</div>
            <div class="text3">{{ $t('lanchpad.cont8.text17') }}</div>
          </div>
          <div class="block5 block">
            <div class="text1">{{ $t('lanchpad.cont8.text8') }}</div>
            <div class="list">
              <p class="text3">{{ $t('lanchpad.cont8.text10') }}</p>
              <p class="text3">{{ $t('lanchpad.cont8.text11') }}</p>
              <p class="text3">{{ $t('lanchpad.cont8.text12') }}</p>
              <p class="text3">{{ $t('lanchpad.cont8.text13') }}</p>
            </div>
          </div>
          <div class="block6 block text2">{{ $t('lanchpad.cont8.text6') }}</div>
          <div class="block7 block text1">{{ $t('lanchpad.cont8.text18') }}</div>
        </div>
        <div class="cont_right">
          <div class="block1 block text1">{{ $t('lanchpad.cont8_new.text21') }}</div>
          <div class="block2 block text2">{{ $t('lanchpad.cont8.text4') }}</div>
          <div class="block3 block">
            <div class="text1">{{ $t('lanchpad.cont8_new.text22') }}</div>
            <div class="text3">{{ $t('lanchpad.cont8_new.text25') }}</div>
            <div class="list">
              <p class="text2">{{ $t('lanchpad.cont8_new.text23') }}</p>
              <p class="text2">{{ $t('lanchpad.cont8_new.text24') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="page page_cont14">
      <div class="page14_title">{{ $t('lanchpad.cont9.title1') }}</div>
      <div class="page14_cont animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
        <div class="cont_img">
          <img  loading="lazy" class="icon" src="../assets/image_img/page14_img2.png" alt="image">
        </div>
        <div class="cont_block">
          <div class="block_top">
            <div class="text_cont">{{ $t('lanchpad.cont9_new.text1') }}</div>
            <div class="line"> - </div>
            <div class="span_cont">{{ $t('lanchpad.cont9_new.text2') }}</div>
            <div class="line">-></div>
            <div class="text_cont">{{ $t('lanchpad.cont9_new.text3') }}</div>
          </div>
          <div class="block_cont">
            <div class="cont_text">
              <span class="num">01</span>
              <p class="text">{{ $t('lanchpad.cont9_new.text5') }}</p>
            </div>
            <div class="cont_text">
              <span class="num">02</span>
              <p class="text">{{ $t('lanchpad.cont9_new.text6') }}</p>
            </div>
            <div class="cont_text">
              <span class="num">03</span>
              <p class="text">{{ $t('lanchpad.cont9_new.text7') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="page page_cont15">
      <div class="page15_bg"></div>
      <div class="page15_title">
        <p>{{ $t('lanchpad.cont10.title') }}</p>
      </div>
      <div class="page15_cont">
        <div class=" cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont10.desc1') }}</p>
          </div>
          <p class="left_text">{{ $t('lanchpad.cont10.text1') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class="cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="right_top">
            <p class="text">{{ $t('lanchpad.cont10.desc10') }}</p>
          </div>
          <p class="right_text">{{ $t('lanchpad.cont10.text10') }}</p>
        </div>
      </div>
      <div class="page15_cont">
        <div class=" cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont10.desc2') }}</p>
          </div>
          <p class="left_text">{{ $t('lanchpad.cont10.text2') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class=" cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="right_top">
            <p class="text">{{ $t('lanchpad.cont10.desc11') }}</p>
          </div>
          <p class="right_text">{{ $t('lanchpad.cont10.text11') }}</p>
        </div>
      </div>
      <div class="page15_cont">
        <div class=" cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont10.desc3') }}</p>
          </div>
          <p class="left_text">{{ $t('lanchpad.cont10.text3') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class=" cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="right_top">
            <p class="text">{{ $t('lanchpad.cont10.desc12') }}</p>
          </div>
          <p class="right_text">{{ $t('lanchpad.cont10.text12') }}</p>
        </div>
      </div>
      <div class="page15_cont">
        <div class=" cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont10.desc4') }}</p>
          </div>
          <p class="left_text">{{ $t('lanchpad.cont10.text4') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class=" cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="right_top">
            <p class="text">{{ $t('lanchpad.cont10.desc13') }}</p>
          </div>
          <p class="right_text">{{ $t('lanchpad.cont10.text13') }}</p>
        </div>
      </div>
      <div class="page15_cont">
        <div class=" cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont10.desc5') }}</p>
          </div>
          <p class="left_text">{{ $t('lanchpad.cont10.text5') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class=" cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="right_top">
            <p class="text">{{ $t('lanchpad.cont10.desc14') }}</p>
          </div>
          <p class="right_text">{{ $t('lanchpad.cont10.text14') }}</p>
        </div>
      </div>
      <div class="page15_cont">
        <div class=" cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont10.desc6') }}</p>
          </div>
          <p class="left_text">{{ $t('lanchpad.cont10.text6') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class=" cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="right_top">
            <p class="text">{{ $t('lanchpad.cont10.desc15') }}</p>
          </div>
          <p class="right_text">{{ $t('lanchpad.cont10.text15') }}</p>
        </div>
      </div>
      <div class="page15_cont">
        <div class=" cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont10.desc7') }}</p>
          </div>
          <p class="left_text">{{ $t('lanchpad.cont10.text7') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class=" cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="right_top">
            <p class="text">{{ $t('lanchpad.cont10.desc16') }}</p>
          </div>
          <p class="right_text">{{ $t('lanchpad.cont10.text16') }}</p>
        </div>
      </div>
      <div class="page15_cont">
        <div class=" cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont10.desc8') }}</p>
          </div>
          <p class="left_text">{{ $t('lanchpad.cont10.text8') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class=" cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="right_top">
            <p class="text">{{ $t('lanchpad.cont10.desc17') }}</p>
          </div>
          <p class="right_text">{{ $t('lanchpad.cont10.text17') }}</p>
        </div>
      </div>
      <div class="page15_cont">
        <div class=" cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont10.desc9') }}</p>
          </div>
          <p class="left_text">{{ $t('lanchpad.cont10.text9') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class="cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}" style="background: none; border: none">
          <div class="right_top">
            <p class="text"></p>
          </div>
          <p class="right_text"></p>
        </div>
      </div>
    </div>
    <div class="page page_cont15">
      <div class="page15_bg"></div>
      <div class="page15_title">
        <p>{{ $t('lanchpad.cont11.title') }}</p>
      </div>
      <div class="page15_cont">
        <div class=" cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont11.desc1') }}</p>
          </div>
          <p class="left_text">{{ $t('lanchpad.cont11.text1') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class="cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="right_top">
            <p class="text">{{ $t('lanchpad.cont11.desc2') }}</p>
          </div>
          <p class="right_text">{{ $t('lanchpad.cont11.text2') }}</p>
        </div>
      </div>
      <div class="page15_cont">
        <div class=" cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont11.desc3') }}</p>
          </div>
          <p class="left_text">{{ $t('lanchpad.cont11.text3') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class=" cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="right_top">
            <p class="text">{{ $t('lanchpad.cont11.desc4') }}</p>
          </div>
          <p class="right_text">{{ $t('lanchpad.cont11.text4') }}</p>
        </div>
      </div>
      <div class="page15_cont">
        <div class=" cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont11.desc5') }}</p>
          </div>
          <p class="left_text">{{ $t('lanchpad.cont11.text5') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class=" cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="right_top">
            <p class="text">{{ $t('lanchpad.cont11.desc6') }}</p>
          </div>
          <p class="right_text">{{ $t('lanchpad.cont11.text6') }}</p>
        </div>
      </div>
      <div class="page15_cont">
        <div class=" cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont11.desc7') }}</p>
          </div>
          <p class="left_text">{{ $t('lanchpad.cont11.text7') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class=" cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="right_top">
            <p class="text">{{ $t('lanchpad.cont11.desc8') }}</p>
          </div>
          <p class="right_text">{{ $t('lanchpad.cont11.text8') }}</p>
        </div>
      </div>
      <div class="page15_cont">
        <div class=" cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont11.desc9') }}</p>
          </div>
          <p class="left_text">{{ $t('lanchpad.cont11.text9_1') }}</p>
          <p class="left_text">{{ $t('lanchpad.cont11.text9_2') }}</p>
          <p class="left_text">{{ $t('lanchpad.cont11.text9_3') }}</p>
          <p class="left_text">{{ $t('lanchpad.cont11.text9_4') }}</p>
          <p class="left_text">{{ $t('lanchpad.cont11.text9_5') }}</p>
          <p class="left_text">{{ $t('lanchpad.cont11.text9_6') }}</p>
          <p class="left_text">{{ $t('lanchpad.cont11.text9_7') }}</p>
          <p class="left_text">{{ $t('lanchpad.cont11.text9_8') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class=" cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="right_top">
            <p class="text">{{ $t('lanchpad.cont11.desc10') }}</p>
          </div>
          <p class="right_text">{{ $t('lanchpad.cont11.text10_1') }}</p>
          <p class="right_text">{{ $t('lanchpad.cont11.text10_2') }}</p>
          <p class="right_text">{{ $t('lanchpad.cont11.text10_3') }}</p>
          <p class="right_text">{{ $t('lanchpad.cont11.text10_4') }}</p>
          <p class="right_text">{{ $t('lanchpad.cont11.text10_5') }}</p>
          <p class="right_text">{{ $t('lanchpad.cont11.text10_6') }}</p>
          <p class="right_text">{{ $t('lanchpad.cont11.text10_7') }}</p>
        </div>
      </div>
      <div class="page15_cont">
        <div class=" cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont11.desc11') }}</p>
          </div>
          <p class="left_text">{{ $t('lanchpad.cont11.text11_1') }}</p>
          <p class="left_text">{{ $t('lanchpad.cont11.text11_2') }}</p>
          <p class="left_text">{{ $t('lanchpad.cont11.text11_3') }}</p>
          <p class="left_text">{{ $t('lanchpad.cont11.text11_4') }}</p>
          <p class="left_text">{{ $t('lanchpad.cont11.text11_5') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class=" cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <div class="right_top">
            <p class="text">{{ $t('lanchpad.cont11.desc12') }}</p>
          </div>
          <p class="right_text">{{ $t('lanchpad.cont11.text12_1') }}</p>
          <p class="right_text">{{ $t('lanchpad.cont11.text12_2') }}</p>
          <p class="right_text">{{ $t('lanchpad.cont11.text12_3') }}</p>
          <p class="right_text">{{ $t('lanchpad.cont11.text12_4') }}</p>
          <p class="right_text">{{ $t('lanchpad.cont11.text12_5') }}</p>
        </div>
      </div>
      <div class="page15_cont">
        <div class=" cont_left animation_hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <div class="left_top">
            <p class="text">{{ $t('lanchpad.cont11.desc13') }}</p>
          </div>
          <p class="left_text">{{ $t('lanchpad.cont11.text13_1') }}</p>
          <p class="left_text">{{ $t('lanchpad.cont11.text13_2') }}</p>
          <p class="left_text">{{ $t('lanchpad.cont11.text13_3') }}</p>
        </div>
        <img  loading="lazy" class="cont_icon" src="../assets/image_img/page11_icon1.png" alt="image">
        <div class=" cont_right animation_hide" v-animate="{delay: 0, class:'fadeInRight'}"></div>
      </div>
    </div>
    <div class="page page_cont16" id="economic">
      <div class="page16_title">Tokenomics</div>
      <div class="page16_cont animation_hide" v-animate="{delay: 0, class:'amplify'}">
        <div class="intro">
          <div class="echarDom" ref="EcharRef"></div>
          <div class="details Total" v-if="!showDraw">
            <div class="title">{{ $t('lanchpad.cont12.text10') }}</div>
            <div class="block">
              <div class="text">{{ $t('lanchpad.cont12.text11') }}</div>
              <div class="desc">{{ $t('lanchpad.cont12.text15') }}</div>
            </div>
            <div class="block">
              <div class="title">{{ $t('lanchpad.cont12.text12') }}</div>
              <div class="text">{{ $t('lanchpad.cont12.text') }}</div>
            </div>
          </div>
          <div class="details Single" :class="{ font12: drawData.datalist && drawData.datalist.length }" v-else>
            <div class="title">{{ drawData.name }}</div>
            <div class="dataList" v-if="drawData.datalist && drawData.datalist.length" v-for="(item, index) in drawData.datalist">
              <div class="data_top"><span></span> {{ drawData.name }} - {{ item.name }}: {{ item.quantity }}%</div>
              <div class="text"><span>{{ $t('lanchpad.cont12.text11') }}</span> {{ item.quantity }} </div>
              <div class="text"><span>{{ $t('lanchpad.cont12.text13') }}</span> 0 </div>
              <div class="text"><span>{{ $t('lanchpad.cont12.text14') }}</span> {{ item.quantity }} </div>
              <div class="text"><span>{{ $t('lanchpad.cont12.text12') }}</span> {{ item.desc }} </div>
            </div>
            <div class="dataList" v-else>
              <div class="text"><span>{{ $t('lanchpad.cont12.text11') }}</span> {{ drawData.value }} </div>
              <div class="text"><span>{{ $t('lanchpad.cont12.text13') }}</span> 0 </div>
              <div class="text"><span>{{ $t('lanchpad.cont12.text14') }}</span> {{ drawData.value }} </div>
              <div class="text"><span>{{ $t('lanchpad.cont12.text12') }}</span> {{ drawData.desc }} </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page page_cont17" id="team">
      <div class="page17_title">{{ $t('lanchpad.cont13.title') }}</div>
      <div class="page17_cont">
        <div class="teamInfo" @mouseout="showdetail = false">
          <div class="imgInfo">
            <img  loading="lazy" class="character" src="../assets/image_img/page17_team_img1.png" alt="image">
          </div>
          <div class="userInfo">
            <p class="name1 user_hide" @click="showInfo(1)">Learn more</p>
            <p class="name user_show">YONG HE</p>
            <p class="desc user_show">{{ $t('lanchpad.cont13.intro1') }}</p>
          </div>
        </div>
        <div class="teamInfo">
          <div class="imgInfo">
            <img  loading="lazy" class="character" src="../assets/image_img/page17_team_img2.png" alt="image">
          </div>
          <div class="userInfo">
            <p class="name1 user_hide" @click="showInfo(2)">Learn more</p>
            <p class="name user_show">PARK JI HYE</p>
            <p class="desc user_show">{{ $t('lanchpad.cont13.intro3') }}</p>
          </div>
        </div>
        <div class="teamInfo">
          <div class="imgInfo">
            <img  loading="lazy" class="character" src="../assets/image_img/page17_team_img3.png" alt="image">
          </div>
          <div class="userInfo">
            <p class="name1 user_hide" @click="showInfo(3)">Learn more</p>
            <p class="name user_show">BRANDO MURPHY</p>
            <p class="desc user_show">{{ $t('lanchpad.cont13.intro8') }}</p>
          </div>
        </div>
        <div class="teamInfo">
          <div class="imgInfo">
            <img  loading="lazy" class="character" src="../assets/image_img/page17_team_img4.png" alt="image">
          </div>
          <div class="userInfo">
            <p class="name1 user_hide" @click="showInfo(4)">Learn more</p>
            <p class="name user_show">VARUN</p>
            <p class="desc user_show">{{ $t('lanchpad.cont13.intro4') }}</p>
          </div>
        </div>
        <!-- <div class="teamInfo">
          <div class="imgInfo">
            <img  loading="lazy" class="character" src="../assets/image_img/page17_team_img5.png" alt="image">
          </div>
          <div class="userInfo">
            <p class="name1 user_hide" @click="showInfo(5)">Learn more</p>
            <p class="name user_show">Manny Salman</p>
            <p class="desc user_show">{{ $t('lanchpad.cont13.intro7') }}</p>
          </div>
        </div> -->
        <div class="teamInfo">
          <div class="imgInfo">
            <img  loading="lazy" class="character" src="../assets/image_img/page17_team_img8.png" alt="image">
          </div>
          <div class="userInfo">
            <p class="name1 user_hide" @click="showInfo(8)">Learn more</p>
            <p class="name user_show">Joseph Alexander</p>
            <p class="desc user_show">{{ $t('lanchpad.cont13.intro7') }}</p>
          </div>
        </div>
        <div class="teamInfo">
          <div class="imgInfo">
            <img  loading="lazy" class="character" src="../assets/image_img/page17_team_img6.png" alt="image">
          </div>
          <div class="userInfo">
            <p class="name1 user_hide" @click="showInfo(6)">Learn more</p>
            <p class="name user_show">Mr. YANG</p>
            <p class="desc user_show">{{ $t('lanchpad.cont13.intro5') }}</p>
          </div>
        </div>
        <div class="teamInfo">
          <div class="imgInfo">
            <img  loading="lazy" class="character" src="../assets/image_img/page17_team_img7.png" alt="image">
          </div>
          <div class="userInfo">
            <p class="name1 user_hide" @click="showInfo(7)">Learn more</p>
            <p class="name user_show">DEREK PARK</p>
            <p class="desc user_show">{{ $t('lanchpad.cont13.intro6') }}</p>
          </div>
        </div>
        <div class="teamInfo">
          <div class="imgInfo">
            <img  loading="lazy" class="character" src="../assets/image_img/page17_team_img9.png" alt="image">
          </div>
          <div class="userInfo">
            <p class="name1 user_hide" @click="showInfo(9)">Learn more</p>
            <p class="name user_show">SUMMER</p>
            <p class="desc user_show">{{ $t('lanchpad.cont13.intro7') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="page page_cont18">
      <div class="page18_title">{{ $t('lanchpad.cont15.title') }}</div>
      <div class="page18_cont">
        <div class="cont18_overflow">
          <div class="cont18_block" ref="cont18_block_scrollLeft">
            <div class="block" v-for="(el, index) in activeInfo1All" :key="index">
              <div class="mock">
                <a :href="el.link" target="_blank" >{{ el.name }}</a>
              </div>
              <img  loading="lazy" :src="require(`@/assets/image_img/${el.img}`)" alt="image">
            </div>
          </div>
        </div>
        <div class="cont18_overflow">
          <div class="cont18_block" ref="cont18_block_scrollLeft1">
            <div class="block" v-for="(el, index) in activeInfo2All" :key="index">
              <div class="mock">
                <a :href="el.link" target="_blank" >{{ el.name }}</a>
              </div>
              <img  loading="lazy" :src="require(`@/assets/image_img/${el.img}`)" alt="image">
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogTableVisible" width="80%" top="20vh" draggable style="background: rgb(46 46 46); border-radius: 20px;">
      <div class="detailsInfo">
        <div class="name">{{ userInfo[showIndex].name }}</div>
        <div class="synopsis">{{ userInfo[showIndex].intro }}</div>
        <div class="text" v-for="(item, index) in userInfo[showIndex].experience" :key="index">{{ item }} </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { defineComponent, computed, ref, onBeforeMount, onBeforeUnmount, onMounted, getCurrentInstance, watch, nextTick } from "vue";
  import { useI18n } from "vue-i18n";
  import { useStore } from "vuex"
  import { useRoute, useRouter } from 'vue-router'
  import banner1 from '@/assets/image_img/banner1.png'
  import banner2 from '@/assets/image_img/banner2.png'
  import banner3 from '@/assets/image_img/banner3.png'
  import banner4 from '@/assets/image_img/banner4.png'
  import banner5 from '@/assets/image_img/banner5.png'
  import banner6 from '@/assets/image_img/banner6.png'
  import banner7 from '@/assets/image_img/banner7.png'
  import banner8 from '@/assets/image_img/banner8.png'
  import banner9 from '@/assets/image_img/banner9.png'
  import banner10 from '@/assets/image_img/banner10.png'
  import banner11 from '@/assets/image_img/banner11.png'
  import banner12 from '@/assets/image_img/banner12.png'
  import banner13 from '@/assets/image_img/banner13.png'
  import banner14 from '@/assets/image_img/banner14.png'
  import banner15 from '@/assets/image_img/banner15.png'
  import banner16 from '@/assets/image_img/banner16.png'
  import banner17 from '@/assets/image_img/banner17.jpg'
  import banner18 from '@/assets/image_img/banner18.png'
  import coverage from "@/components/coverage.vue";
  import * as echarts from 'echarts';
  export default defineComponent({
    name: 'Lanchpad',
    components: {
      coverage,
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      let lan = computed(() => {
        return store.state.lan
      })
      const { t, locale } = useI18n();
      const instance = getCurrentInstance()
      const timer = ref(null)
      const images = ref([
        banner1,
        banner2,
        banner3,
        banner4,
        banner5,
        banner6,
        banner7,
        banner8,
        banner9,
        banner10,
        banner11,
        banner12,
        banner13,
        banner14,
        banner15,
        banner16,
        banner17,
        banner18
      ])
      // 将图片列表复制两份，实现无缝滚动
      const displayedImages = computed(() => [...images.value, ...images.value]);
      const scrollAmount = ref(0);
      const scrollStep = ref(2); // 每次滚动的像素数
      const carouselInner = ref(null); // 使用 ref 获取 DOM 元素
      const startScroll = () => {
        const scrollStepFunc = () => {
          if (carouselInner.value) {
            scrollAmount.value += scrollStep.value;
            carouselInner.value.scrollLeft = scrollAmount.value;
            // 当滚动到第一组图片的最后一张时，重置 scrollAmount
            if (scrollAmount.value >= carouselInner.value.scrollWidth / 2) {
              scrollAmount.value = 0;
            }
          }
          requestAnimationFrame(scrollStepFunc);
        };
        requestAnimationFrame(scrollStepFunc);
      };
      
      const activeInfo1 = ref([
        { name: t('lanchpad.cont15.name1'), img: 'page18_lanch_cont15img1.png', link: 'https://twitter.com/DeepLinkGlobal/status/1666819765401456645' },
        { name: t('lanchpad.cont15.name2'), img: 'page18_lanch_cont15img2.png', link: 'https://twitter.com/DeepLinkGlobal/status/1655215428111855616' },
        { name: t('lanchpad.cont15.name3'), img: 'page18_lanch_cont15img3.png', link: 'https://twitter.com/deepbrainfeng/status/1647529443945226241' },
        { name: t('lanchpad.cont15.name4'), img: 'page18_lanch_cont15img4.png', link: 'https://twitter.com/DeepLinkGlobal/status/1645438888696061961' },
        { name: t('lanchpad.cont15.name5'), img: 'page18_lanch_cont15img5.png', link: 'https://twitter.com/DeepLinkGlobal/status/1638801572325982211' },
        { name: t('lanchpad.cont15.name6'), img: 'page18_lanch_cont15img6.png', link: 'https://twitter.com/DeepLinkGlobal/status/1638800911022604288' },
        { name: t('lanchpad.cont15.name7'), img: 'page18_lanch_cont15img7.png', link: 'https://twitter.com/DeepLinkGlobal/status/1636052278850338849' },
        { name: t('lanchpad.cont15.name8'), img: 'page18_lanch_cont15img8.png', link: 'https://twitter.com/deepbrainfeng/status/1626592760806670340' },
        { name: t('lanchpad.cont15.name9'), img: 'page18_lanch_cont15img9.png', link: 'https://twitter.com/deepbrainfeng/status/1615370872252137473' },
        { name: t('lanchpad.cont15.name10'), img: 'page18_lanch_cont15img10.jpg', link: 'https://twitter.com/DeepLinkVietNam/status/1710551891061756038' },
        { name: t('lanchpad.cont15.name11'), img: 'page18_lanch_cont15img11.jpg', link: 'javascript:void(0)' },
        { name: t('lanchpad.cont15.name12'), img: 'page18_lanch_cont15img12.jpg', link: 'https://twitter.com/deepbrainfeng/status/1737496574278725733' },
        { name: t('lanchpad.cont15.name13'), img: 'page18_lanch_cont15img13.png', link: 'https://x.com/DeepLinkGlobal/status/1779328676653674607' },
        { name: t('lanchpad.cont15.name14'), img: 'page18_lanch_cont15img14.png', link: 'https://x.com/DeepLinkGlobal/status/1780990818133926167' },
        { name: t('lanchpad.cont15.name15'), img: 'page18_lanch_cont15img15.png', link: 'https://x.com/DeepLinkGlobal/status/1781536846264279143' },
        { name: t('lanchpad.cont15.name31'), img: 'page18_lanch_cont15img31.jpg', link: 'https://x.com/DeepLinkGlobal/status/1861408457188098375' },
      ])

      const activeInfo2 =ref([
        { name: t('lanchpad.cont15.name16'),  img: 'page18_lanch_cont15img16.png', link: 'https://x.com/DeepLinkGlobal/status/1781775141006868562' },
        { name: t('lanchpad.cont15.name17'),  img: 'page18_lanch_cont15img17.png', link: 'https://x.com/deepbrainfeng/status/1782119869405909398' },
        { name: t('lanchpad.cont15.name18'),  img: 'page18_lanch_cont15img18.png', link: 'https://x.com/DeepLinkGlobal/status/1782136865912750084' },
        { name: t('lanchpad.cont15.name19'),  img: 'page18_lanch_cont15img19.png', link: 'https://x.com/DeepLinkGlobal/status/1782837730356912583' },
        { name: t('lanchpad.cont15.name20'),  img: 'page18_lanch_cont15img20.png', link: 'https://x.com/DeepLinkGlobal/status/1793960511035629878' },
        { name: t('lanchpad.cont15.name21'),  img: 'page18_lanch_cont15img21.jpg', link: 'https://x.com/DeepLinkGlobal/status/1795995261665214967' },
        { name: t('lanchpad.cont15.name22'),  img: 'page18_lanch_cont15img22.jpg', link: 'https://x.com/DeepLinkGlobal/status/1796550503192817797' },
        { name: t('lanchpad.cont15.name23'),  img: 'page18_lanch_cont15img23.jpg', link: 'https://x.com/DeepLinkGlobal/status/1799036124150821272' },
        { name: t('lanchpad.cont15.name24'),  img: 'page18_lanch_cont15img24.jpg', link: 'https://x.com/DeepLinkGlobal/status/1801746483722785141' },
        { name: t('lanchpad.cont15.name25'),  img: 'page18_lanch_cont15img25.jpg', link: 'https://x.com/DeepLinkGlobal/status/1817790266239324574' },
        { name: t('lanchpad.cont15.name26'),  img: 'page18_lanch_cont15img26.jpg', link: 'https://x.com/DeepLinkGlobal/status/1829689780470493283' },
        { name: t('lanchpad.cont15.name27'),  img: 'page18_lanch_cont15img27.jpg', link: 'https://x.com/DeepLinkGlobal/status/1830528766306926845' },
        { name: t('lanchpad.cont15.name28'),  img: 'page18_lanch_cont15img28.jpg', link: 'https://x.com/DeepLinkGlobal/status/1836229234450407518' },
        { name: t('lanchpad.cont15.name29'),  img: 'page18_lanch_cont15img29.jpg', link: 'https://x.com/DeepLinkGlobal/status/1836591009071010274' },
        { name: t('lanchpad.cont15.name30'),  img: 'page18_lanch_cont15img30.jpg', link: 'https://x.com/DeepLinkGlobal/status/1856278919126843563' },
        { name: t('lanchpad.cont15.name32'),  img: 'page18_lanch_cont15img32.jpg', link: 'https://x.com/DeepLinkGlobal/status/1866440481879785550' },
      ])
      // 将图片列表复制两份，实现无缝滚动
      const activeInfo1All = computed(() => [...activeInfo1.value, ...activeInfo1.value]);
      const activeInfo2All = computed(() => [...activeInfo2.value, ...activeInfo2.value]);
      const scrollAmount1 = ref(0);
      const scrollStep1 = ref(2); // 每次滚动的像素数
      const cont18_block_scrollLeft = ref(null)
      const cont18_block_scrollLeft1 = ref(null)
      const startScroll1 = () => {
        const scrollStepFunc = () => {
          if (cont18_block_scrollLeft.value) {
            scrollAmount1.value += scrollStep1.value;
            cont18_block_scrollLeft.value.scrollLeft = scrollAmount1.value;
            // 当滚动到第一组图片的最后一张时，重置 scrollAmount
            if (scrollAmount1.value >= cont18_block_scrollLeft.value.scrollWidth / 2) {
              scrollAmount1.value = 0;
            }
          }
          requestAnimationFrame(scrollStepFunc);
        };
        requestAnimationFrame(scrollStepFunc);
      };

      const scrollAmount2 = ref(0);
      const scrollStep2 = ref(2); // 每次滚动的像素数
      const startScroll2 = () => {
        scrollAmount2.value = cont18_block_scrollLeft1.value.scrollWidth / 2;
        const scrollStepFunc = () => {
          if (cont18_block_scrollLeft1.value) {
            scrollAmount2.value -= scrollStep2.value;
            cont18_block_scrollLeft1.value.scrollLeft = scrollAmount2.value;
            // 当滚动到第一组图片的最后一张时，重置 scrollAmount
            if (scrollAmount2.value <= 0) {
              scrollAmount2.value = cont18_block_scrollLeft1.value.scrollWidth / 2;
            }
          }
          requestAnimationFrame(scrollStepFunc);
        };
        requestAnimationFrame(scrollStepFunc);
      };

      const options = ref({
        legend: {
          // top: '5%',
          bottom: '5%',
          left: 'center'
        },
        backgroundColor: 'transparent',
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['25%', '70%'],
            avoidLabelOverlap: false,
            padAngle: 5,
            itemStyle: {
              borderRadius: 10
            },
            backgroundColor: 'transparent',
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 30,
                fontWeight: 'bold',
                color: '#fff'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 15, name: t('lanchpad.cont12.text2'), desc: t('lanchpad.cont12.desc2') },
              { value: 29.4, name: t('lanchpad.cont12.text1') , 
                datalist: [
                  { name: t('lanchpad.cont12.text1_1'), quantity: '10', desc: t('lanchpad.cont12.desc1_1') },
                  { name: t('lanchpad.cont12.text1_2'), quantity: '10', desc: t('lanchpad.cont12.desc1_2') },
                  { name: t('lanchpad.cont12.text1_3'), quantity: '5', desc: t('lanchpad.cont12.desc1_3') },
                  { name: t('lanchpad.cont12.text1_4'), quantity: '4.4', desc: t('lanchpad.cont12.desc1_4') },
                ]
              },
              { value: 7, name: t('lanchpad.cont12.text3'), desc: t('lanchpad.cont12.desc3') },
              { value: 3.6, name: t('lanchpad.cont12.text4'), desc: t('lanchpad.cont12.desc4') },
              { value: 23, name: t('lanchpad.cont12.text5') , 
                datalist: [
                  { name: t('lanchpad.cont12.text5_1'), quantity: '16', desc: t('lanchpad.cont12.desc5_1') },
                  { name: t('lanchpad.cont12.text5_2'), quantity: '4', desc: t('lanchpad.cont12.desc5_2') },
                  { name: t('lanchpad.cont12.text5_3'), quantity: '3', desc: t('lanchpad.cont12.desc5_3') },
                ]
              },
              { value: 10, name: t('lanchpad.cont12.text6'), desc: t('lanchpad.cont12.desc6') },
              { value: 3, name: t('lanchpad.cont12.text7'), desc: t('lanchpad.cont12.desc7') },
              { value: 5, name: t('lanchpad.cont12.text8'), desc: t('lanchpad.cont12.desc8') },
              { value: 4, name: t('lanchpad.cont12.text9') , 
                datalist: [
                  { name: t('lanchpad.cont12.text9_1'), quantity: '2', desc: t('lanchpad.cont12.desc9_1') },
                  { name: t('lanchpad.cont12.text9_2'), quantity: '2', desc: t('lanchpad.cont12.desc9_2') }
                ]
              },
            ]
          }
        ]
      })
      const showDraw = ref(false)
      const drawData = ref(null)
      const userInfo = ref({
        1: { 
          name: 'YONG HE', 
          intro: t('lanchpad.cont13.intro1'), 
          experience: [
            t('lanchpad.cont13.user1_1'),
            t('lanchpad.cont13.user1_2'),
            t('lanchpad.cont13.user1_3'),
            t('lanchpad.cont13.user1_4'),
            t('lanchpad.cont13.user1_5'),
          ]
        },
        // 2: { 
        //   name: 'GUANGHUI LUO', 
        //   intro: t('lanchpad.cont13.intro2'), 
        //   experience: [
        //     t('lanchpad.cont13.user2_1'),
        //     t('lanchpad.cont13.user2_2'),
        //     t('lanchpad.cont13.user2_3'),
        //     t('lanchpad.cont13.user2_4')
        //   ]
        // },
        2: { 
          name: 'PARK JI HYE', 
          intro: t('lanchpad.cont13.intro3'), 
          experience: [
            t('lanchpad.cont13.user3_1'),
            t('lanchpad.cont13.user3_2'),
            // t('lanchpad.cont13.user3_3'),
            t('lanchpad.cont13.user3_4'),
            t('lanchpad.cont13.user3_5')
          ]
        },
        3: { 
          name: 'BRANDO MURPHY', 
          intro: t('lanchpad.cont13.intro8'), 
          experience: [
            t('lanchpad.cont13.user9_4'),
            t('lanchpad.cont13.user9_1'),
            t('lanchpad.cont13.user9_2'),
            t('lanchpad.cont13.user9_3'),
            t('lanchpad.cont13.user9_5'),
            t('lanchpad.cont13.user9_6')
          ]
        },
        4: { 
          name: 'VARUN', 
          intro: t('lanchpad.cont13.intro4'), 
          experience: [
            t('lanchpad.cont13.user4_1'),
            t('lanchpad.cont13.user4_2'),
            t('lanchpad.cont13.user4_3'),
            t('lanchpad.cont13.user4_4'),
            t('lanchpad.cont13.user4_5'),
            t('lanchpad.cont13.user4_6'),
            t('lanchpad.cont13.user4_7'),
            t('lanchpad.cont13.user4_8'),
            t('lanchpad.cont13.user4_9'),
            t('lanchpad.cont13.user4_10'),
            t('lanchpad.cont13.user4_11'),
            t('lanchpad.cont13.user4_12'),
            t('lanchpad.cont13.user4_13'),
          ]
        },
        5: {
          name: 'Manny Salman', 
          intro: t('lanchpad.cont13.intro7'), 
          experience: [
            t('lanchpad.cont13.user11_1'),
            t('lanchpad.cont13.user11_2'),
            t('lanchpad.cont13.user11_3')
          ]
        },
        6: { 
          name: 'Mr. YANG', 
          intro: t('lanchpad.cont13.intro5'), 
          experience: [
            t('lanchpad.cont13.user5_1'),
            t('lanchpad.cont13.user5_2'),
            t('lanchpad.cont13.user5_3'),
            t('lanchpad.cont13.user5_4'),
            t('lanchpad.cont13.user5_5')
          ]
        },
        7: { 
          name: 'DEREK PARK', 
          intro: t('lanchpad.cont13.intro6'), 
          experience: [
            t('lanchpad.cont13.user6_1'),
            t('lanchpad.cont13.user6_2'),
            t('lanchpad.cont13.user6_3'),
            t('lanchpad.cont13.user6_4'),
            t('lanchpad.cont13.user6_5'),
            t('lanchpad.cont13.user6_6'),
            t('lanchpad.cont13.user6_7'),
            t('lanchpad.cont13.user6_8'),
            t('lanchpad.cont13.user6_9')
          ]
        },
        8: { 
          name: 'Joseph Alexander', 
          intro: t('lanchpad.cont13.intro7'), 
          experience: [
            t('lanchpad.cont13.user7_1'),
            t('lanchpad.cont13.user7_2'),
            t('lanchpad.cont13.user7_3')
          ]
        },
        // 9: { 
        //   name: 'Maryna Barysheva', 
        //   intro: t('lanchpad.cont13.intro7'), 
        //   experience: [
        //     t('lanchpad.cont13.user8_1'),
        //     t('lanchpad.cont13.user8_2'),
        //     t('lanchpad.cont13.user8_3'),
        //     t('lanchpad.cont13.user8_4')
        //   ]
        // },
        9: { 
          name: 'SUMMER', 
          intro: t('lanchpad.cont13.intro7'), 
          experience: [
            t('lanchpad.cont13.user10_1'),
            t('lanchpad.cont13.user10_2'),
            t('lanchpad.cont13.user10_3')
          ]
        }
      })
      const showIndex = ref(1)
      const showdetail = ref(false)
      const showInfo = (el) => {
        showIndex.value = el
        showdetail.value = true
        dialogTableVisible.value = true
      }
      const organInfo = ref([
        t('lanchpad.cont14.organ1'),
        t('lanchpad.cont14.organ2'),
        t('lanchpad.cont14.organ3'),
        t('lanchpad.cont14.organ4'),
        t('lanchpad.cont14.organ5')
      ])
      const organIndex = ref(1)
      const organdetail = ref(false)
      const showInfo1 = (el) => {
        organIndex.value = el
        organdetail.value = true
      }

      const dialogTableVisible = ref(false)
      const openDialog = () => {
        instance.refs.videoRef.play()
      }
      const closeDialog = () => {
        instance.refs.videoRef.pause()
      }
      const drawChart = () => {
        const chart = echarts.init(instance.refs.EcharRef, 'dark', { renderer: 'svg' })
        chart.setOption(options.value);
        chart.on('mouseover', function (params) {
          drawData.value = params.data
          showDraw.value = true
        });
        chart.on('mouseout', function (params) {
          showDraw.value = false
        });
      }
      
      const pointList = ref([
        { top: '2740', active: false },
        { top: '2910', active: false },
        { top: '4050', active: false },
        { top: '4320', active: false },
        { top: '4460', active: false },
        { top: '4600', active: false },
        { top: '5035', active: false },
        { top: '5345', active: false },
        { top: '5885', active: false },
        { top: '6050', active: false },
        { top: '6400', active: false },
        { top: '6625', active: false },
        { top: '6700', active: false },
        { top: '6795', active: false, samll: true },
        { top: '6880', active: false, samll: true },
        { top: '6950', active: false, samll: true },
        { top: '7040', active: false, samll: true },
        { top: '7125', active: false, samll: true },
        { top: '7165', active: false, samll: true },
        { top: '7220', active: false, samll: true },
        { top: '7255', active: false, samll: true },
        { top: '7310', active: false, samll: true },
        { top: '7400', active: false }
      ])
      const downLoad = (el) => {
        if (el == 'white') {
          // if (lan.value == 'ko') {
          //   window.open('https://www.deeplink.cloud/DeepLink_Whitepaper_KO.pdf', 'target')
          // } else {
            window.open('https://www.deeplink.cloud/DeepLink_Whitepaper.pdf', 'target')
          // }
        } else {
          if (lan.value == 'ko') {
            window.open('https://www.deeplink.cloud/DeepLink_Protocol_KO.pdf', 'target')
          } else if (lan.value == 'ja') {
            window.open('https://www.deeplink.cloud/DeepLink_Protocol_JA.pdf', 'target')
          } else {
            window.open('https://www.deeplink.cloud/DeepLink_Protocol.pdf', 'target')
          }
        }
      }
      const linkHref = (el) => {
        window.open(el, 'target')
      }
      const linkHref1 = (el) => {
        router.push({path: el})
        localStorage.setItem('path', el)
        store.commit('changePath', el)
      }
      onMounted(async () => {
        drawChart()
        await nextTick();
        startScroll()
        startScroll1()
        startScroll2()
      })
      onBeforeUnmount(() => {
        clearInterval(timer.value);
        timer.value = null;
      })
      watch(
        () => locale.value,
        () => {
          organInfo.value = [
            t('lanchpad.cont14.organ1'),
            t('lanchpad.cont14.organ2'),
            t('lanchpad.cont14.organ3'),
            t('lanchpad.cont14.organ4'),
            t('lanchpad.cont14.organ5')
          ]
          userInfo.value = {
            1: { 
              name: 'YONG HE', 
              intro: t('lanchpad.cont13.intro1'), 
              experience: [
                t('lanchpad.cont13.user1_1'),
                t('lanchpad.cont13.user1_2'),
                t('lanchpad.cont13.user1_3'),
                t('lanchpad.cont13.user1_4'),
                t('lanchpad.cont13.user1_5'),
              ]
            },
            // 2: { 
            //   name: 'GUANGHUI LUO', 
            //   intro: t('lanchpad.cont13.intro2'), 
            //   experience: [
            //     t('lanchpad.cont13.user2_1'),
            //     t('lanchpad.cont13.user2_2'),
            //     t('lanchpad.cont13.user2_3'),
            //     t('lanchpad.cont13.user2_4')
            //   ]
            // },
            2: { 
              name: 'PARK JI HYE', 
              intro: t('lanchpad.cont13.intro3'), 
              experience: [
                t('lanchpad.cont13.user3_1'),
                t('lanchpad.cont13.user3_2'),
                // t('lanchpad.cont13.user3_3'),
                t('lanchpad.cont13.user3_4'),
                t('lanchpad.cont13.user3_5')
              ]
            },
            3: { 
              name: 'BRANDO MURPHY', 
              intro: t('lanchpad.cont13.intro8'), 
              experience: [
                t('lanchpad.cont13.user9_4'),
                t('lanchpad.cont13.user9_1'),
                t('lanchpad.cont13.user9_2'),
                t('lanchpad.cont13.user9_3'),
                t('lanchpad.cont13.user9_5'),
                t('lanchpad.cont13.user9_6')
              ]
            },
            4: { 
              name: 'VARUN', 
              intro: t('lanchpad.cont13.intro4'), 
              experience: [
                t('lanchpad.cont13.user4_1'),
                t('lanchpad.cont13.user4_2'),
                t('lanchpad.cont13.user4_3'),
                t('lanchpad.cont13.user4_4'),
                t('lanchpad.cont13.user4_5'),
                t('lanchpad.cont13.user4_6'),
                t('lanchpad.cont13.user4_7'),
                t('lanchpad.cont13.user4_8'),
                t('lanchpad.cont13.user4_9'),
                t('lanchpad.cont13.user4_10'),
                t('lanchpad.cont13.user4_11'),
                t('lanchpad.cont13.user4_12'),
                t('lanchpad.cont13.user4_13'),
              ]
            },
            5: {
              name: 'Manny Salman', 
              intro: t('lanchpad.cont13.intro7'), 
              experience: [
                t('lanchpad.cont13.user11_1'),
                t('lanchpad.cont13.user11_2'),
                t('lanchpad.cont13.user11_3')
              ]
            },
            6: { 
              name: 'Mr. YANG', 
              intro: t('lanchpad.cont13.intro5'), 
              experience: [
                t('lanchpad.cont13.user5_1'),
                t('lanchpad.cont13.user5_2'),
                t('lanchpad.cont13.user5_3'),
                t('lanchpad.cont13.user5_4'),
                t('lanchpad.cont13.user5_5')
              ]
            },
            7: { 
              name: 'DEREK PARK', 
              intro: t('lanchpad.cont13.intro6'), 
              experience: [
                t('lanchpad.cont13.user6_1'),
                t('lanchpad.cont13.user6_2'),
                t('lanchpad.cont13.user6_3'),
                t('lanchpad.cont13.user6_4'),
                t('lanchpad.cont13.user6_5'),
                t('lanchpad.cont13.user6_6'),
                t('lanchpad.cont13.user6_7'),
                t('lanchpad.cont13.user6_8'),
                t('lanchpad.cont13.user6_9')
              ]
            },
            8: { 
              name: 'Joseph Alexander', 
              intro: t('lanchpad.cont13.intro7'), 
              experience: [
                t('lanchpad.cont13.user7_1'),
                t('lanchpad.cont13.user7_2'),
                t('lanchpad.cont13.user7_3')
              ]
            },
            // 9: { 
            //   name: 'Maryna Barysheva', 
            //   intro: t('lanchpad.cont13.intro7'), 
            //   experience: [
            //     t('lanchpad.cont13.user8_1'),
            //     t('lanchpad.cont13.user8_2'),
            //     t('lanchpad.cont13.user8_3'),
            //     t('lanchpad.cont13.user8_4')
            //   ]
            // },
            9: { 
              name: 'SUMMER', 
              intro: t('lanchpad.cont13.intro7'), 
              experience: [
                t('lanchpad.cont13.user10_1'),
                t('lanchpad.cont13.user10_2'),
                t('lanchpad.cont13.user10_3')
              ]
            }
          }
          options.value = {
            legend: {
              // top: '5%',
              bottom: '5%',
              left: 'center'
            },
            backgroundColor: 'transparent',
            series: [
              {
                name: '',
                type: 'pie',
                radius: ['25%', '70%'],
                avoidLabelOverlap: false,
                padAngle: 5,
                itemStyle: {
                  borderRadius: 10
                },
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 30,
                    fontWeight: 'bold',
                    color: '#fff'
                  }
                },
                labelLine: {
                  show: false
                },
                data: [
                  { value: 15, name: t('lanchpad.cont12.text2'), desc: t('lanchpad.cont12.desc2') },
                  { value: 30, name: t('lanchpad.cont12.text1') , 
                    datalist: [
                      { name: t('lanchpad.cont12.text1_1'), quantity: '10', desc: t('lanchpad.cont12.desc1_1') },
                      { name: t('lanchpad.cont12.text1_2'), quantity: '10', desc: t('lanchpad.cont12.desc1_2') },
                      { name: t('lanchpad.cont12.text1_3'), quantity: '5', desc: t('lanchpad.cont12.desc1_3') },
                      { name: t('lanchpad.cont12.text1_4'), quantity: '10', desc: t('lanchpad.cont12.desc1_4') },
                    ]
                  },
                  { value: 12, name: t('lanchpad.cont12.text3'), desc: t('lanchpad.cont12.desc3') },
                  { value: 5, name: t('lanchpad.cont12.text4'), desc: t('lanchpad.cont12.desc4') },
                  { value: 20, name: t('lanchpad.cont12.text5'), desc: t('lanchpad.cont12.desc5') },
                  { value: 10, name: t('lanchpad.cont12.text6'), desc: t('lanchpad.cont12.desc6') },
                  { value: 3, name: t('lanchpad.cont12.text7'), desc: t('lanchpad.cont12.desc7') }
                ]
              }
            ]
          }
          drawChart()
        }
      )
      return {
        lan,
        showDraw,
        drawData,
        pointList,
        userInfo,
        showIndex,
        showdetail,
        dialogTableVisible,
        openDialog,
        closeDialog,
        showInfo,
        organInfo,
        organIndex,
        organdetail,
        showInfo1,
        linkHref,
        linkHref1,
        downLoad,
        displayedImages,
        activeInfo1All,
        activeInfo2All,
        carouselInner,
        cont18_block_scrollLeft,
        cont18_block_scrollLeft1
      };
    }
  })
</script> 

<style lang="scss" scoped>
.Lanchpad {
  position: relative;
  z-index: 2;
  width: 100%;
  min-width: 600px;
  font-style: normal;
  overflow: hidden;
  .page {
    position: relative;
    z-index: 10;
    min-width: 600px;
  }
  .page_cont1 {
    width: 100%;
    position: relative;
    overflow: hidden;
    text-align:center;
    min-height: 700px;
    height: calc(100vh - 106px);
    background: url(../assets/image_img/homebg.png) no-repeat;
    background-position: bottom;
    background-size: 100%;
    .title {
      width: 90%;
      color: #fff;
      margin: auto;
      line-height: 1;
      font-size: 58px;
      font-weight: 600;
      max-width: 1200px;
      margin-top: 120px;
      font-family: Monda;
      p {
        margin-bottom: 30px;
        :last-child {
          margin-bottom: 0;
        }
      }
    }
    .text {
      width: 80%;
      margin: auto;
      font-size: 20px;
      font-family: Outfit;
      color: rgba(255, 255, 255, 0.5)
    }
    .btnList {
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      max-width: 900px;
      align-items: center;
      justify-content: space-around;
      .btn {
        position: relative;
        color: #fff;
        line-height: 1;
        cursor: pointer;
        font-size: 20px;
        font-weight: 500;
        margin-top: 30px;
        padding: 16px 80px;
        font-family: Monda;
        border-radius: 25px;
        // overflow: hidden;
        // background: rgba(255, 255, 255, 0.1);
        background: #191d1c;
        // border: 5px solid transparent;
        // border: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    .carousel {
      position: absolute;
      bottom: 40px;
      overflow: hidden;
      width: 100%;
      height: 40px;
      .carousel-inner {
        display: flex;
        overflow: auto;
        padding-bottom: 10px;
        transition: scroll-left 0.5s ease;
      }

      .carousel-item {
        flex: 0 0 auto;
        margin: 0 50px;
      }
      img {
        height: 40px;
        object-fit: cover;
      }
    }
  }
  .page_cont2 {
    margin: 100px 0 200px;
    .videoPaly {
      width: 90%;
      margin: auto;
      max-width: 900px;
      video {
        width: 100%;
      }
    }
  }
  .page_cont3 {
    z-index: 5;
    margin-bottom: 100px;
    .page3_bg {
      position: absolute;
      left: 0;
      z-index: 1;
      .page3_bg1 {
        position: absolute;
        top: -800px;
        width: 987px;
        z-index: 2;
        height: 1531px;
        background: url(../assets/image_img/page3_bg1.png) no-repeat 100%/100%;
      }
      .page3_bg2 {
        position: absolute;
        top: -330px;
        z-index: 1;
        opacity: 0.4;
        width: 449px;
        height: 490px;
        background: url(../assets/image_img/page3_bg2.png) no-repeat 100% / 100%;
      }
    }
    .page3_cont {
      position: relative;
      z-index: 3;
      width: 90%;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      max-width: 1200px;
      padding: 75px 20px;
      border-radius: 57px;
      background: #000403;
      align-items: center;
      box-sizing: border-box;
      justify-content: space-around;
      .video1 {
        width: 453px;
        overflow: hidden;
        border-radius: 43px;
        border: 7px solid rgba(255, 255, 255, 0.05);
        video {
          width: 453px;
        }
      }
      .video2 {
        width: 453px;
        overflow: hidden;
        border-radius: 43px;
        border: 7px solid rgba(255, 255, 255, 0.05);
        video {
          width: 453px;
        }
      }
      .text {
        color: #FFF;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        line-height: normal;
        .distance {
          margin-bottom: 15px;
          font-family: Monda;
          font-weight: 600;
          font-size: 20px;
          color: #fff;
        }
        .num {
          display: flex;
          padding: 17px;
          align-items: center;
          border-radius: 15px;
          box-sizing: border-box;
          color: rgba(255, 255, 255, 0.4);
          background: rgba(255, 255, 255, 0.08);
        }
      }
    }
  }
  .page_cont4 {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    align-items: center;
    margin: 0 auto 200px;
    justify-content: space-around;
    .page4_cont{
      padding: 30px;
      display: flex;
      margin-top: 28px;
      align-items: center;
      border-radius: 28px;
      font-family: Outfit;
      transition: ease 1s;
      box-sizing: border-box;
      background: rgba(3, 255, 145, 0.05);
      &:hover {
        background: #00FFD1;
        .cont_num {
          color: #fff;
          background: #000403;
        }
        .cont_text {
          color: #000403;
        }
      }
      .cont_num {
        color: #000403;
        font-size: 32px;
        font-weight: 700;
        padding: 10px 15px;
        margin-right: 25px;
        border-radius: 12px;
        background: #03FF91;
        box-sizing: border-box;
      }
      .cont_text {
        color: #fff;
        font-size: 20px;
      }
    }
  }
  .page_cont5 {
    z-index: 5;
    margin-bottom: 100px;
    .page5_bg {
      position: absolute;
      left: 50%;
      right: 50%;
      z-index: 1;
      top: -250px;
      width: 600px;
      height: 600px;
      margin-left: -300px;
      background: url(../assets/image_img/page5_bg.png) no-repeat 100%/100%;
    }
    .page5_cont {
      position: relative;
      z-index: 2;
      width: 90%;
      margin: auto;
      max-width: 1200px;
      .page5_text {
        width: 100%;
        display: flex;
        justify-content: space-around;
        .text1 {
          width: 45%;
          line-height: 34px;
          font-family: Monda;
          text-align: right;
          font-weight: 600;
          font-size: 30px;
          color: #fff;
          .arrow {
            display: inline-block;
            margin-top: 10px;
            width: 163px;
          }
        }
        .text2 {
          width: 45%;
          line-height: 34px;
          text-align: left;
          font-size: 20px;
          font-family: Outfit;
          color: rgba(255, 255, 255, 0.5);
        }
      }
      .page5_img {
        width: 535px;
        margin: auto;
        img {
          width: 535px;
        }
      }
    }
  }
  .page_cont6 {
    z-index: 5;
    margin-bottom: 100px;
    .page6_bg {
      position: absolute;
      left: 50%;
      right: 50%;
      z-index: 1;
      top: -500px;
      width: 1512px;
      height: 1450px;
      margin-left: -756px;
      background: url(../assets/image_img/page6_bg.png) no-repeat 100%/100%;
    }
    .page6_cont {
      position: relative;
      z-index: 2;
      width: 90%;
      margin: auto;
      min-width: 600px;
      max-width: 1200px;
      .page6_title {
        width: 100%;
        color: #fff;
        display: flex;
        font-size: 36px;
        font-weight: 600;
        line-height: 40px;
        font-family: Monda;
        text-align: center;
        align-items: center;
        margin-bottom: 30px;
        flex-direction: column;
        p {
          margin-bottom: 10px;
        }
        span {
          color: transparent;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(90deg, #8000FF 0%, #00FFD1 100%); 
        }
      }
      .page6_text {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        font-family: Outfit;
        margin-bottom: 50px;
        color: rgba(255, 255, 255, 0.5);
      }
      .page6_shows {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        min-width: 600px;
        justify-content: space-between;
        .show_cont {
          width: 280px;
          padding: 25px;
          height: 430px;
          display: flex;
          border-radius: 25px;
          background: #000403;
          flex-direction: column;
          box-sizing: border-box;
          justify-content: space-between;
          &.mt_80 {
            margin-top: 80px;
          }
          .title {
            width: 100%;
            display: flex;
            line-height: 1;
            font-size: 26px;
            font-weight: 600;
            text-align: center;
            font-family: Monda;
            align-items: center;
            margin-bottom: 15px;
            justify-content: center; 
            color: rgba(255, 255, 255, 0.5);
          }
          .span {
            gap: 12px;
            width: 100%;
            display: flex;
            color: #03FF91;
            font-size: 20px;
            padding: 8px 30px;
            font-style: normal;
            margin-bottom: 8px;
            line-height: normal;
            font-family: Outfit;
            align-items: center;
            border-radius: 13px;
            box-sizing: border-box;
            justify-content: center;
            background: rgba(255, 255, 255, 0.05);
          }
          .img {
            gap: 12px;
            width: 100%;
            display: flex;
            padding: 15px;
            margin: 8px 0;
            border-radius: 13px;
            align-items: center;
            box-sizing: border-box;
            justify-content: center;
            background: rgba(255, 255, 255, 0.05);
            img {
              max-width: 100%;
              max-height: 40px;
            }
          }
          .link {
            color: #fff;
            height: 30px;
            display: flex;
            padding: 0 15px;
            font-size: 14px;
            font-family: Outfit;
            align-items: center;
            box-sizing: border-box;
            justify-content: space-between;
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }
  .page_cont7 {
    z-index: 5;
    margin-bottom: 100px;
    .page7_cont {
      position: relative;
      z-index: 2;
      width: 90%;
      margin: auto;
      min-width: 600px;
      max-width: 1200px;
      .page7_title {
        width: 100%;
        color: #fff;
        display: flex;
        font-size: 36px;
        font-weight: 600;
        line-height: 40px;
        font-family: Monda;
        text-align: center;
        align-items: center;
        margin-bottom: 30px;
        flex-direction: column;
        p {
          margin-bottom: 10px;
        }
        span {
          color: transparent;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(90deg, #8000FF 0%, #00FFD1 100%); 
        }
      }
      .page7_text {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        font-family: Outfit;
        margin-bottom: 50px;
        color: rgba(255, 255, 255, 0.5);
      }
      .page7_tool {
        margin: auto;
        display: table;
        font-size: 20px;
        color: #000403;
        padding: 15px 20px;
        border-radius: 9px;
        font-family: Outfit;
        background: #03FF91;
        box-sizing: border-box;
      }
      .page7_desc {
        width: 600px;
        margin: auto;
        display: flex;
        margin-top: 20px;
        align-items: center;
        justify-content: space-around;
        .desc_block {
          width: 180px;
          font-size: 20px;
          color: #000403;
          padding: 15px 20px;
          border-radius: 9px;
          font-family: Outfit;
          background: #03FF91;
          box-sizing: border-box;
        }
        .desc_img {
          width: 220px;
          img {
            width: 220px;
          }
        }
      }
      .page7_num {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;
        justify-content: space-between;
        .num_block {
          width: 280px;
          padding: 35px;
          display: flex;
          height: 240px;
          border-radius: 15px;
          flex-direction: column;
          box-sizing: border-box;
          justify-content: space-between;
          background: rgba(255, 255, 255, 0.1);
          &.mt_50 {
            margin-top: 50px;
          }
          .text1 {
            font-family: Monda;
            font-weight: 600;
            font-size: 36px;
            color: #03FF91;
          }
          .text2 {
            // height: 80px;
            color: #fff;
            font-size: 20px;
            font-family: Outfit;
          }
        }
      }
    }
  }
  .page_cont8 {
    position: relative;
    z-index: 2;
    width: 90%;
    margin: auto;
    max-width: 1200px;
    margin-bottom: 150px;
    .page8_title {
      width: 100%;
      color: #fff;
      font-size: 36px;
      font-weight: 600;
      line-height: 40px;
      font-family: Monda;
      text-align: center;
      margin-bottom: 100px;
    }
    .page8_btns {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      .page8_btn {
        color: #fff;
        display: flex;
        font-size: 16px;
        font-family: Monda;
        padding: 25px 30px;
        margin-bottom: 20px;
        align-items: center;
        border-radius: 12px;
        text-decoration: none;
        background: rgba(255, 255, 255, 0.1);
        .icon {
          width: 24px;
          margin-right: 20px;
        }
      }
    }
  }
  .page_cont9 {
    position: relative;
    z-index: 2;
    width: 90%;
    margin: auto;
    max-width: 1200px;
    margin-bottom: 150px;
    .page9_title {
      width: 100%;
      color: #fff;
      font-size: 36px;
      font-weight: 600;
      line-height: 40px;
      font-family: Monda;
      text-align: center;
      margin-bottom: 100px;
    }
    .page9_pros {
      width: 100%;
      display: flex;
      justify-content: space-around;
      .pro_block {
        width: 45%;
        padding: 40px;
        display: flex;
        text-align: center;
        border-radius: 15px;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: space-between;
        background: rgba(255, 255, 255, 0.1);
        .text1 {
          color: #03FF91;
          font-size: 30px;
          font-family: Outfit;
          margin-bottom: 30px;
        }
        .text2 {
          font-size: 20px;
          font-family: Outfit;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
  .page_cont10 {
    position: relative;
    z-index: 2;
    width: 90%;
    margin: auto;
    max-width: 1200px;
    margin-bottom: 150px;
    .page10_bg {
      position: absolute;
      left: 50%;
      right: 50%;
      z-index: 1;
      top: -85px;
      width: 1372px;
      height: 1394px;
      margin-left: -686px;
      background: url(../assets/image_img/page10_bg.png) no-repeat 100%/100%;
    }
    .page10_title {
      width: 100%;
      color: #fff;
      font-size: 36px;
      font-weight: 600;
      line-height: 40px;
      font-family: Monda;
      text-align: center;
      margin-bottom: 100px;
    }
    .page10_cont {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cont_center {
        position: relative;
        z-index: 5;
        width: 383px;
        height: 640px;
        background: url(../assets/image_img/page10_img1.png) no-repeat 100%/100%;
        background-position: top;
        .center_top {
          width: 376px;
          height: 94px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .center_block {
            width: 48%;
            color: #fff;
            font-size: 16px;
            text-align: right;
            font-family: Outfit;
            &.left {
              text-align: left;
            }
          }
        }
        .center_bottom {
          position: absolute;
          bottom: 0;
          width: 100%;
          color: #fff;
          height: 100px;
          font-size: 20px;
          text-align: center;
          font-family: Outfit;
        }
      }
      .cont_block {
        position: relative;
        flex: 1;
        height: 530px;
        padding-top: 48px;
        border-radius: 20px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0.05);
        .title {
          color: #fff;
          font-size: 30px;
          text-align: center;
          font-family: Outfit;
          margin-bottom: 10px;
        }
        .text {
          font-size: 20px;
          text-align: center;
          font-family: Outfit;
          color: rgba(255, 255, 255, 0.5);
        }
        .bg_img {
          position: absolute;
          opacity: .5;
          width: 100%;
          bottom: 0;
        }
        &.left {
          margin-right: 20px;
          
        }
        &.right {
          margin-left: 20px;
        }
      }
    }
  }
  .page_cont11 {
    position: relative;
    z-index: 2;
    width: 90%;
    margin: auto;
    max-width: 1200px;
    margin-bottom: 150px;
    .page11_title {
      width: 100%;
      color: #fff;
      font-size: 36px;
      font-weight: 600;
      line-height: 40px;
      font-family: Monda;
      text-align: center;
      margin-bottom: 100px;
    }
    .page11_cont {
      width: 100%;
      display: flex;
      margin-bottom: 35px;
      box-sizing: border-box;
      justify-content: space-between;
      .cont_left {
        width: 45%;
        padding: 15px 20px;
        border-radius: 20px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0.05);
        .left_top {
          width: 100%;
          display: flex;
          margin-bottom: 30px;
          align-items: center;
          justify-content: right;
          .text {
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            text-align: right;
            font-family: Monda;
          }
          .icon {
            width: 30px;
            margin: 0 5px;
          }
        }
        .left_text {
          text-align: right;
          font-size: 16px;
          text-align: right;
          font-family: Outfit;
          color: rgba(255, 255, 255, 0.5);
        }
      }
      .cont_icon {
        width: 53px;
        height: 53px;
        align-self: center;
      }
      .cont_right {
        width: 45%;
        padding: 15px 20px;
        border-radius: 20px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0.05);
        .right_top {
          width: 100%;
          display: flex;
          margin-bottom: 30px;
          align-items: center;
          justify-content: left;
          .text {
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            text-align: right;
            font-family: Monda;
          }
          .icon {
            width: 30px;
            margin: 0 5px;
          }
        }
        .right_text {
          font-size: 16px;
          font-family: Outfit;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
  .page_cont12 {
    position: relative;
    z-index: 2;
    width: 90%;
    margin: auto;
    max-width: 1200px;
    margin-bottom: 150px;
    .page12_title {
      width: 100%;
      color: #fff;
      font-size: 36px;
      font-weight: 600;
      line-height: 40px;
      font-family: Monda;
      text-align: center;
      margin-bottom: 30px;
    }
    .page12_text {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      font-family: Outfit;
      margin-bottom: 100px;
      color: rgba(255, 255, 255, 0.5);
    }
    .page12_cont {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 65px;
      box-sizing: border-box;
      justify-content: space-between;
      .cont_img1 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 342px;
        height: 342px;
        margin-right: 45px;
        background: url(../assets/image_img/page12_img1.png) no-repeat 100%/100%;
        img {
          width: 689px;
          height: 782px;
        }
      }
      .cont_block1 {
        flex: 1;
        padding: 40px;
        border-radius: 15px;
        box-sizing: border-box;
        background: linear-gradient(90deg, #8000FF 0%, #00FFD1 100%);
        .title {
          width: 100%;
          color: #000403;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          font-family: Monda;
          margin-bottom: 55px;
        }
        .text {
          font-size: 16px;
          line-height: 20px;
          font-family: Outfit;
          color: rgba(255, 255, 255);
        }
      }
      .cont_img2 {
        width: 362px;
        height: 445px;
        margin-left: 45px;
        background: url(../assets/image_img/page12_img2.png) no-repeat 100%/100%;
      }
      .cont_block2 {
        flex: 1;
        padding: 40px;
        text-align: right;
        border-radius: 15px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0.05);
        .title {
          width: 100%;
          color: #fff;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          font-family: Monda;
          margin-bottom: 55px;
        }
        .text {
          font-size: 16px;
          line-height: 20px;
          font-family: Outfit;
          color: rgba(255, 255, 255, .5);
        }
      }
    }
  }
  .page_cont13 {
    position: relative;
    z-index: 2;
    width: 90%;
    margin: auto;
    min-width: 600px;
    max-width: 1200px;
    margin-bottom: 150px;
    .page13_title {
      width: 100%;
      color: #fff;
      font-size: 36px;
      font-weight: 600;
      line-height: 40px;
      font-family: Monda;
      text-align: center;
      margin-bottom: 100px;
    }
    .page13_cont {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      .text1 {
        line-height: 1;
        font-size: 24px;
        color: #03FF91;
        font-weight: 600;
        font-family: Monda;
        text-align: center;
        align-items: center;
        display: inline-flex;
        justify-content: center;
      }
      .text2 {
        line-height: 1;
        font-size: 10px;
        text-align: center;
        font-family: Outfit;
        align-items: center;
        display: inline-flex;
        justify-content: center;
        color: rgba(255, 255, 255, .5);
      }
      .text3 {
        line-height: 1;
        font-size: 16px;
        text-align: center;
        font-family: Outfit;
        align-items: center;
        display: inline-flex;
        justify-content: center;
        color: rgba(255, 255, 255);
      }
      .block {
        padding: 10px 20px;
        box-sizing: border-box;
      }
      .cont_left {
        position: relative;
        width: 773px;
        height: 463px;
        margin-bottom: 30px;
        background: url(../assets/image_img/page13_bg1.png) no-repeat 100%/100%;
        .block1 {
          position: absolute;
          top: 0;
          left: 226px;
          width: 241px;
          height: 88px;
        }
        .block2 {
          position: absolute;
          top: 88px;
          left: 82px;
          width: 82px;
          height: 36px;
        }
        .block3 {
          position: absolute;
          top: 88px;
          left: 527px;
          width: 83px;
          height: 35px;
        }
        .block4 {
          position: absolute;
          top: 150px;
          left: 0;
          width: 250px;
          height: 151px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-around;
        }
        .block5 {
          position: absolute;
          top: 154px;
          right: 0;
          width: 415px;
          height: 254px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-around;
          .list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            p {
              width: 100%;
              text-align: left;
              margin-bottom: 10px;
            }
          }
        }
        .block6 {
          position: absolute;
          top: 332px;
          left: 82px;
          width: 83px;
          height: 35px;
        }
        .block7 {
          position: absolute;
          bottom: 0;
          left: 38px;
          width: 158px;
          height: 88px;
        }
      }
      .cont_right {
        position: relative;
        width: 324px;
        height: 478px;
        margin-bottom: 30px;
        background: url(../assets/image_img/page13_bg2.png) no-repeat 100%/100%;
        .block1 {
          position: absolute;
          top: 0;
          right: 40px;
          width: 252px;
          height: 88px;
        }
        .block2 {
          position: absolute;
          top: 145px;
          width: 82px;
          right: 127px;
          height: 36px;
        }
        .block3 {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 324px;
          height: 247px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-around;
          .list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            p {
              width: 100%;
              text-align: left;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  .page_cont14 {
    position: relative;
    z-index: 2;
    width: 90%;
    margin: auto;
    max-width: 1200px;
    margin-bottom: 150px;
    .page14_title {
      width: 100%;
      color: #fff;
      font-size: 36px;
      font-weight: 600;
      line-height: 40px;
      font-family: Monda;
      text-align: center;
      margin-bottom: 100px;
    }
    .page14_cont {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 65px;
      box-sizing: border-box;
      justify-content: space-around;
      .cont_img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 454px;
        height: 425px;
        margin-right: 45px;
        margin-bottom: 30px;
        background: url(../assets/image_img/page14_img1.png) no-repeat 100%/100%;
        img {
          width: 905px;
          height: 1006px;
        }
      }
      .cont_block {
        flex: 1;
        padding: 30px;
        display: flex;
        min-width: 600px;
        margin-bottom: 30px;
        align-items: center;
        border-radius: 23px;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: space-between;
        border:  1px solid rgba(3, 255, 145, 0.19);
        .block_top {
          display: flex;
          justify-content: space-between;
          width: 100%;
          .text_cont {
            color: #fff;
            padding: 20px;
            font-size: 16px;
            font-family: Outfit;
            border-radius: 14px;
            box-sizing: border-box;
            background: rgba(255, 255, 255, .05);
          }
          .line {
            width: 40px;
            color: #03FF91;
            align-self: center;
            text-align: center;
          }
          .span_cont {
            font-size: 10px;
            border-radius: 6px;
            align-self: center;
            padding: 10px 20px;
            font-family: Outfit;
            box-sizing: border-box;
            color: rgba(255, 255, 255, .5);
            background: rgba(255, 255, 255, .05);
          }
        }
        .block_cont {
          margin-top: 25px;
          padding: 30px 40px;
          border-radius: 14px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, .05);
          .cont_text {
            display: flex;
            justify-content: space-between;
            .num {
              color: #03FF91;
              font-size: 20px;
              font-weight: 600;
              margin-right: 10px;
              font-family: Outfit;
            }
            .text {
              flex: 1;
              font-size: 16px;
              line-height: 27px;
              font-family: Outfit;
              color: rgba(255, 255, 255, .5);
            }
          }
        }
      }
    }
  }
  .page_cont15 {
    z-index: 5;
    margin-bottom: 150px;
    .page15_bg {
      position: absolute;
      z-index: 1;
      top: -450px;
      right: -200px;
      width: 689px;
      height: 782px;
      background: url(../assets/image_img/page12_img1bg.png) no-repeat 100%/100%;
    }
    .page15_title {
      width: 100%;
      color: #fff;
      font-size: 36px;
      font-weight: 600;
      line-height: 40px;
      font-family: Monda;
      text-align: center;
      margin-bottom: 100px;
    }
    .page15_cont {
      width: 100%;
      display: flex;
      max-width: 1200px;
      margin: 0 auto 35px;
      box-sizing: border-box;
      justify-content: space-between;
      .cont_left {
        width: 45%;
        padding: 30px;
        border-radius: 15px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0.01);
        border: 1px solid rgba(255, 255, 255, 0.03);
        .left_top {
          width: 100%;
          display: flex;
          margin-bottom: 15px;
          align-items: center;
          justify-content: right;
          .text {
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            text-align: right;
            font-family: Monda;
          }
        }
        .left_text {
          font-size: 16px;
          text-align: right;
          font-family: Outfit;
          color: rgba(255, 255, 255, 0.5);
        }
      }
      .cont_icon {
        width: 53px;
        height: 53px;
        align-self: center;
      }
      .cont_right {
        width: 45%;
        padding: 30px;
        border-radius: 15px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0.01);
        border: 1px solid rgba(255, 255, 255, 0.03);
        .right_top {
          width: 100%;
          display: flex;
          margin-bottom: 15px;
          align-items: center;
          justify-content: left;
          .text {
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            text-align: right;
            font-family: Monda;
          }
        }
        .right_text {
          font-size: 16px;
          font-family: Outfit;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
  .page_cont16 {
    position: relative;
    z-index: 2;
    width: 90%;
    margin: auto;
    max-width: 1200px;
    margin-bottom: 150px;
    .page16_title {
      width: 100%;
      color: #fff;
      font-size: 36px;
      font-weight: 600;
      line-height: 40px;
      font-family: Monda;
      text-align: center;
      margin-bottom: 100px; 
    }
    .page16_cont {
      .intro {
        max-width: 1200px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-radius: 20px;
        justify-content: space-around;
        .echarDom {
          width: 600px;
          height: 600px;
        }
        .details {
          width: 480px;
          // height: 440px;
          overflow: hidden;
          padding: 24px 20px;
          line-height: normal;
          border-radius: 24px;
          box-sizing: border-box;
          backdrop-filter: blur(6px);
          background: rgba(255, 255, 255, 0.05);
          &.Total {
            text-align: center;
            padding: 24px 80px;
            .title {
              color: #03FF91;
              font-size: 20px;
              padding: 10px 44px;
              font-family: Outfit;
              margin-bottom: 16px;
              border-radius: 14px;
              display: inline-block;
              background: rgba(255, 255, 255, 0.14);
            }
            .block {
              text-align: center;
              margin-bottom: 24px;
              .text {
                color: #fff;
                font-size: 16px;
                font-family: Outfit;
              }
              .desc {
                color: #fff;
                font-size: 48px;
                font-weight: 600;
                font-family: Monda;
              }
            }
            
          }
          &.Single {
            .title {
              margin: auto;
              display: table;
              color: #03FF91;
              font-size: 20px;
              padding: 10px 44px;
              font-family: Outfit;
              margin-bottom: 16px;
              border-radius: 14px;
              background: rgba(255, 255, 255, 0.14);
            }
            .dataList {
              .data_top {
                display: flex;
                font-size: 16px;
                color: #363F5E;
                align-items: center;
                span {
                  width: 8px;
                  height: 8px;
                  margin-right: 8px;
                  border-radius: 100%;
                  display: inline-block;
                  background: #363F5E;
                }
              }
              .text {
                font-size: 14px;
                color: rgba(255, 255, 255);
                span {
                  font-weight: 400;
                  color: rgba(255, 255, 255, 0.5);
                }
              }
            }
          }
          &.font12 {
            padding: 12px 10px;
            .title {
              font-size: 16px;
              font-weight: 600;
              color: #03FF91;
              text-align: center;
              margin-bottom: 8px;
            }
            .dataList {
              margin-bottom: 5px;
              .data_top {
                display: flex;
                font-size: 14px;
                color: rgba(255, 255, 255);
                align-items: center;
                span {
                  width: 8px;
                  height: 8px;
                  margin-right: 8px;
                  border-radius: 100%;
                  display: inline-block;
                  background: #03FF91;
                }
              }
              .text {
                font-size: 12px;
                color: rgba(255, 255, 255);
                span {
                  font-weight: 400;
                  color: rgba(255, 255, 255, 0.5);
                }
              }
            }
          }
        }
      }
    }
  }
  .page_cont17 {
    position: relative;
    z-index: 2;
    width: 90%;
    margin: auto;
    max-width: 1200px;
    margin-bottom: 150px;
    .page17_title {
      width: 100%;
      color: #fff;
      font-size: 36px;
      font-weight: 600;
      line-height: 40px;
      font-family: Monda;
      text-align: center;
      margin-bottom: 100px;
    }
    .page17_cont {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 65px;
      box-sizing: border-box;
      justify-content: space-around;
      .teamInfo {
        width: 280px;
        &:hover { 
          .imgInfo {
            border: 3px solid transparent;
            background: url(../assets/image_img/page17_bg2.png) no-repeat 100%/100%;
          }
          .userInfo {
            .user_show {
              transition: ease 1s;
              display: none;
            }
            .user_hide {
              transition: ease 1s;
              display: block;
            }
            .name1 {
              cursor: pointer;
              font-size: 20px;
              font-weight: 600;
              line-height: 48px;
              font-family: Monda;
              color: transparent;
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-image: linear-gradient(90deg, #8000FF 0%, #00FFD1 100%);
            }
          }
        }
        .imgInfo {
          position: relative;
          width: 280px;
          height: 390px;
          // padding: 5px;
          overflow: hidden;
          border-radius: 20px;
          transition: ease 1s;
          box-sizing: border-box;
          border: 3px solid rgba(255, 255, 255, .05);
          background: url(../assets/image_img/page17_bg1.png) no-repeat;
          background-size: 291px 402px;
          background-position: center;
          .character {
            position: absolute;
            bottom: 0;
            width: 100%;
          }
        }
        .userInfo {
          width: 100%;
          height: 88px;
          display: flex;
          text-align: center;
          align-items: center;
          flex-direction: column;
          .user_show {
            transition: ease 1s;
            display: block;
          }
          .user_hide {
            transition: ease 1s;
            display: none;
          }
          .name {
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            line-height: 48px;
            font-family: Monda;
            transition: ease 1s;
          }
          .desc {
            font-size: 16px;
            line-height: 18px;
            font-family: Outfit;
            color: rgba(255, 255, 255, .5);
          }
        }
      }
    }
  }
  .page_cont18 {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    margin: 0 auto 150px;
    .page18_title {
      width: 100%;
      color: #fff;
      font-size: 36px;
      font-weight: 600;
      line-height: 40px;
      font-family: Monda;
      text-align: center;
      margin-bottom: 100px;
    }
    .page18_cont {
      width: 100%;
      padding: 80px 0;
      box-sizing: border-box;
      border-top: 1px solid;
      border-image: linear-gradient(90deg, #8000FF, #00FFD1) 1;
      background: rgba(255, 255, 255, .05);
      .cont18_overflow {
        width: 100%;
        height: 270px;
        overflow: hidden;
        .cont18_block {
          display: flex;
          align-items: center;
          overflow: auto;
          .block {
            position: relative;
            display: flex;
            width: 340px;
            height: 252px;
            margin: 0 10px;
            overflow: hidden;
            align-items: center;
            justify-content: center;
            transform: skew(45deg);
            border-radius: 6px;
            flex: 0 0 auto;
            border: 2px solid transparent;
            &:hover {
              border: 2px solid #03FF91;
              .mock {
                display: block;
              }
            }
            &:first-child {
              margin-left: 135px;
            }
            img {
              width: 788px;
              transform: skew(-45deg);
            }
            .mock {
              display: none;
              position: absolute;
              z-index: 1;
              width: 100%;
              height: 100%;
              background: rgb(33 66 36 / 80%);
              a {
                position: absolute;
                bottom: 20px;
                width: 60%;
                left: 50%;
                right: 50%;
                color: #03FF91;
                font-size: 16px;
                font-weight: 600;
                line-height: 1.2;
                margin-left: -30%;
                font-family: Monda;
                text-decoration: none;
                transform: skew(-45deg);
              }
            }
          }
        }

      }
    }
  }
  .detailsInfo {
    width: 100%;
    padding: 30px;
    text-align: center;
    border-radius: 24px;
    line-height: normal;
    box-sizing: border-box;
    .name {
      color: #fff;
      font-size: 36px;
      font-weight: 600;
      font-family: Monda;
      margin-bottom: 20px;
    }
    .synopsis {
      color: #03FF91;
      font-size: 16px;
      font-weight: 600;
      font-family: Outfit;
      margin-bottom: 40px;
    }
    .text {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 10px;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
.cont18_block {
  display: flex;
  padding: 10px 0;
  // align-items: center;
  // overflow: auto;
  .block {
    position: relative;
    display: flex;
    width: 340px;
    height: 252px;
    margin: 0 10px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    transform: skew(45deg);
    border-radius: 6px;
    flex: 0 0 auto;
    border: 2px solid transparent;
    &:hover {
      border: 2px solid #03FF91;
      .mock {
        display: block;
      }
    }
    &:first-child {
      margin-left: 135px;
    }
    img {
      width: 788px;
      transform: skew(-45deg);
    }
    .mock {
      display: none;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: rgb(33 66 36 / 80%);
      a {
        position: absolute;
        bottom: 20px;
        width: 60%;
        left: 50%;
        right: 50%;
        color: #03FF91;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.2;
        margin-left: -30%;
        font-family: Monda;
        text-decoration: none;
        transform: skew(-45deg);
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .Lanchpad {
    .page_cont1 {
      min-height: 900px;
    }
    .page_cont6 {
      width: 100%;
      .page6_cont {
        width: 100%;
        .page6_title {
          width: 90%;
          margin: 0 auto 30px;
        }
        .page6_text {
          width: 90%;
          margin: 0 auto 50px;
        }
        .page6_shows {
          .show_cont {
            width: 220px;
            margin-bottom: 50px;
            &.mt_80 {
              margin-top: 80px;
            }
          }
        }
      }
    }
    .page_cont7 {
      .page7_cont {
        width: 100%;
        
        .page7_title {
          width: 90%;
          margin: 0 auto 30px;
        }
        .page7_text {
          width: 90%;
          margin: 0 auto 50px;
        }
        .page7_num {
          .num_block {
            width: 220px;
            padding: 35px 10px;
          }
        }
      }
    }
  }
  
}

@media screen and (max-width: 900px) {
  .Lanchpad {
    .page_cont6 {
      .page6_cont {
        .page6_shows {
          width: 90%;
          margin: auto;
          .show_cont {
            width: 260px;
            margin-bottom: 50px;
            &.mt_80 {
              margin-top: 0px;
            }
          }
        }
      }
    }
    .page_cont7 {
      .page7_cont {
        width: 90%;
        .page7_num {
          .num_block {
            width: 280px;
            margin-bottom: 50px;
            &.mt_50 {
              margin-top: 0;
            }
          }
        }
      }
    }
    .page_cont10 {
      .page10_cont {
        .cont_center {
          width: 255px;
          height: 420px;
          .center_top {
            width: 250px;
            height: 60px;
            .center_block {
              font-size: 12px;
            }
          }
          .center_bottom {
            position: absolute;
            bottom: -20px;
            width: 100%;
            color: #fff;
            height: 100px;
            font-size: 16px;
            text-align: center;
            font-family: Outfit;
          }
        }
        .cont_block {
          height: 370px;
          .title {
            font-size: 24px;
          }
          .text {
            font-size: 18px;
          }
        }
      }
    }
    .page_cont12 {
      .page12_cont {
        .cont_img1 {
          width: 200px;
        }
        .cont_img2 {
          width: 200px;
        }
      }
    }
    .page_cont13 {
      .page13_cont {
        .cont_left {
          position: relative;
          width: 600px;
          height: 360px;
          margin-bottom: 30px;
          background: url(../assets/image_img/page13_bg1.png) no-repeat 100%/100%;
          .block1 {
            position: absolute;
            top: 0;
            left: 164px;
            width: 210px;
            height: 67px;
          }
          .block2 {
            position: absolute;
            top: 65px;
            left: 56px;
            width: 82px;
            height: 36px;
          }
          .block3 {
            position: absolute;
            top: 65px;
            left: 400px;
            width: 83px;
            height: 35px;
          }
          .block4 {
            position: absolute;
            top: 118px;
            left: 0;
            padding: 10px;
            width: 197px;
            height: 117px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;
          }
          .block5 {
            position: absolute;
            top: 120px;
            right: 0;
            width: 315px;
            height: 205px;
            display: flex;
            padding: 10px;
            text-align: center;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;
            .list {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: space-between;
              p {
                width: 100%;
                text-align: center;
                margin-bottom: 10px;
              }
            }
          }
          .block6 {
            position: absolute;
            top: 253px;
            left: 55px;
            width: 83px;
            height: 35px;
            padding: 10px;
          }
          .block7 {
            position: absolute;
            bottom: 0;
            left: 33px;
            width: 120px;
            height: 68px;
          }
        }
        .cont_right {
          position: relative;
          width: 324px;
          height: 478px;
          margin-bottom: 30px;
          background: url(../assets/image_img/page13_bg2.png) no-repeat 100%/100%;
          .block1 {
            position: absolute;
            top: 0;
            right: 40px;
            width: 252px;
            height: 88px;
          }
          .block2 {
            position: absolute;
            top: 145px;
            width: 82px;
            right: 127px;
            height: 36px;
          }
          .block3 {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 324px;
            height: 247px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;
            .list {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: space-between;
              p {
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
  
}
</style>

<style>

@property --btn_angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
.Lanchpad .page_cont1 .btnList .btn::after, .Lanchpad .page_cont1 .btnList .btn::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: -2px;
  left: -2px;
  z-index: -1;
  padding: 2px;
  border-radius: 25px;
  background-image: conic-gradient(from var(--btn_angle), transparent 50%, #03FF91);
  animation: 3s spin linear infinite;
}

.Lanchpad .page_cont1 .btnList .btn::before {
  filter: blur(10px);
  opacity: 0.5;
}

@keyframes spin {
  from {
    --btn_angle: 0deg;
  }
  to {
    --btn_angle: 360deg;
  }
}

</style>

